import React, {useMemo} from 'react';
import './AboutMe.scss';
import DownloadCVButton from '../../../shared/components/DownloadCVButton';
import ProfilPic from '../../../shared/components/ProfilPic';
import InfoItem from './InfoItem';
import SkillBar from './SkillBar';


function AboutMe() {
    const age = useMemo(() => Math.floor((new Date().getTime() - new Date('1993-12-29').getTime()) / 3.15576e+10), [])

    const infos = [
        {
            infoName: 'Name',
            infoValue: 'Thibault RAPIN'
        },
        {
            infoName: 'Residence',
            infoValue: 'Rennes, France'
        },
        {
            infoName: 'Age',
            infoValue: age.toString()
        },
        {
            infoName: 'Experience',
            infoValue: '6 years'
        },
        {
            infoName: 'Freelance',
            infoValue: 'Available (full remote)'
        },
        {
            infoName: 'Email',
            infoValue: 'contact@thibaultrapin.fr'
        }
    ]

    const technicalSkills = [
        {
            skillName: 'Java',
            skillDescription: 'Spring, Hibernate, Junit, JavaFX',
            skillProgress: 90
        },
        {
            skillName: 'Angular',
            skillDescription: 'PrimeNg, NgZorro',
            skillProgress: 75
        },
        {
            skillName: 'Web',
            skillDescription: 'Javascript/Typescript, HTML, CSS',
            skillProgress: 80
        },
        {
            skillName: 'SQL',
            skillDescription: 'PostgreSQL, H2',
            skillProgress: 70
        },
        {
            skillName: 'Requêtes',
            skillDescription: 'REST, SOAP, GraphQL',
            skillProgress: 70
        },
        {
            skillName: 'CI/CD',
            skillDescription: 'Jenkins, Sonar, Gitlab',
            skillProgress: 85
        },
        {
            skillName: 'Notion de C#, C++, Kotlin, Unity, ReactJs...',
            skillProgress: 30
        }
    ]

    const languageSkills = [
        {
            skillName: 'French',
            skillDescription: 'Native',
            skillProgress: 95
        },
        {
            skillName: 'English',
            skillDescription: 'Advanced (TOEIC 980)',
            skillProgress: 85
        },
        {
            skillName: 'German',
            skillDescription: 'Basic',
            skillProgress: 30
        }
    ]


    return (
        <>
            <div className="whoami-container">
                <div className="bio-container">
                    <ProfilPic size="max(50px, 30vmin)" radius="25%"/>
                    <div className="description-container">
                        <span className="short-description first-letter"><span className="first-letter">H</span>i there ! I'm a software developer</span>
                        <p className="description first-letter">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tristique interdum odio sed convallis. Suspendisse eros magna, fringilla
                            non
                            odio non, hendrerit vehicula neque. Maecenas pulvinar, urna vitae volutpat fermentum, mauris sem accumsan felis, et ultrices enim
                            tellus
                            vel elit. Vestibulum imperdiet commodo mauris sit amet suscipit. Nunc elit libero, bibendum et sollicitudin nec, tristique ut magna.

                        </p>
                        <p className="description first-letter">
                            Vestibulum ligula purus, laoreet id semper in, hendrerit quis lectus. Pellentesque habitant morbi tristique senectus et netus et
                            malesuada fames ac turpis egestas. Mauris ut tellus erat. Sed volutpat aliquet elit, quis condimentum erat facilisis vel. Ut sodales
                            finibus malesuada. Sed sit amet enim nec sem scelerisque rutrum in sit amet urna. Etiam tincidunt elit eu aliquet aliquam. Mauris
                            eleifend turpis et erat ullamcorper, eu accumsan nibh mollis.
                        </p>

                    </div>
                    <div className="info-container">
                        {infos.map(i => <InfoItem key={i.infoName} infoName={i.infoName} infoValue={i.infoValue}/>)}
                        <div className="download-button-container">
                            <DownloadCVButton/>
                        </div>
                    </div>
                </div>

                <div className="skills-container">
                    <div className="technical-skills">
                        <div className="skills-title">Technical skills</div>
                        {technicalSkills.map(s => <SkillBar key={s.skillName} skillName={s.skillName} skillDescription={s.skillDescription}
                                                            skillProgress={s.skillProgress}/>)}
                    </div>
                    <div className="other-skills">
                        <div className="soft-skills">
                            <div className="skills-title">Soft skills</div>
                            <span className="soft-skills-items">Involvement, Motivation, Adaptability, Fast learning, Problem solving, Critical thinking, Teamwork, Rigor, Attention to detail...</span>
                        </div>
                        <div className="language-skills">
                            <div className="skills-title">Languages</div>
                            {languageSkills.map(s => <SkillBar key={s.skillName} skillName={s.skillName} skillDescription={s.skillDescription}
                                                               skillProgress={s.skillProgress}/>)}
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="whoami-container">*/}
            {/*    */}
            {/*</div>*/}
        </>
    );
}

export default AboutMe;
