import React, {RefObject, useRef} from 'react';
import {Button} from '@nextui-org/react';

interface HiddenLinkProps {
    linkRef: RefObject<HTMLAnchorElement>
}

function HiddenLink(props: HiddenLinkProps) {

    // TODO pourquoi pas %PUBLIC_URL%
    // TODO pourquoi download ne fonctionne pas
    return <a href="/CV_Thibault-RAPIN.pdf" target="_blank"
              ref={props.linkRef} hidden></a>;
}

function DownloadCVButton() {
    const downloadLinkRef = useRef<HTMLAnchorElement>(null)

    function downloadCv() {
        if (downloadLinkRef.current)
            downloadLinkRef.current.click();
    }

    return (
        <React.Fragment>
            <Button onClick={downloadCv}>Download my CV</Button>
            <HiddenLink linkRef={downloadLinkRef}/>
        </React.Fragment>
    );
}

export default DownloadCVButton;
