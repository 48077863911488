import React from 'react';
import './SkillBar.scss';
import {Progress} from '@nextui-org/react';

interface Props {
    skillName: string,
    skillDescription?: string
    skillProgress: number
}

function SkillBar(props: Props) {

    return (
        <div className="skill-bar">
            <div className="skill-bar-text">
                <span className="skill-bar-name">{props.skillName}</span>
                <span className="skill-bar-description">{props.skillDescription}</span>
            </div>
            <Progress value={props.skillProgress} color="primary" squared size="sm"/>
        </div>
    );
}

export default SkillBar;
