import React from 'react';
import '../App.scss';
import DownloadCVButton from '../../shared/components/DownloadCVButton';

function WorkInProgress() {

    return (
        <>
            <i className="material-icons md-settings cog-icon"/>
            This website is a Work in Progress
            <DownloadCVButton/>
        </>
    );
}

export default WorkInProgress;
