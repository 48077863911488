import React from 'react';
import './ProfilPic.scss'

interface Props {
    size: string,
    radius?: string
    margin?: string
}

function ProfilPic(props: Props) {
    const profilpic = require('../../assests/profil_pic_clear.png');

    const style = {
        width: props.size,
        height: props.size,
        borderRadius: props.radius ?? '50%',
        margin: props.margin ?? '0'
    }

    return (
        <img className="profil-pic" src={profilpic} style={style} alt="profil"/>
    );
}

export default ProfilPic;
