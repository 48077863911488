import React from 'react';
import '../App.scss';

function NotFound() {


    return (
        <>
            There's nothing here ! <br/>
            Are you lost ?
        </>
    );
}

export default NotFound;
