import React, {useEffect, useState} from 'react';
import './Home.scss';
import DownloadCVButton from '../../../shared/components/DownloadCVButton';
import ProfilPic from '../../../shared/components/ProfilPic';
import {Link} from 'react-router-dom';
import {Page, pageInformation} from '../../App';

const nameTitle: string[] = [
    'Software engineer',
    'Fullstack developer',
    'Code enthusiast',
    'Freelance'
]

function Home() {
    const [indexNameTitle, setIndexNameTitle] = useState(0)

    useEffect(() => {
        const timer = setInterval(
            () => setIndexNameTitle((indexNameTitle + 1) % nameTitle.length),
            5000
        )

        return () => clearInterval(timer)
    })

    return (
        <>
            <div className="name-image-container">
                <div className="name-container">
                    <span className="name"><span className="first-letter">T</span>hibault <span className="first-letter">R</span>apin</span>
                    <span className="name-title">{nameTitle[indexNameTitle]}</span>

                </div>
                <ProfilPic size="max(50px, 30vmin)" margin="5vmin"/>
            </div>
            <div className="button-container">
                <DownloadCVButton/>
                <div className="link-container">
                    <Link to={pageInformation[Page.ABOUT_ME].link} className="link-next">Learn more ➜</Link>
                </div>
            </div>
        </>
    );
}

export default Home;
