import React from 'react';
import '../App.scss';
import {Page, pageInformation} from '../App';
import {Link, useMatch, useResolvedPath} from 'react-router-dom';

interface Props {
    page: Page,
    currentPage: Page
    setCurrentPage: (page: Page) => void
}

function NavBarItem(props: Props) {

    function handleClick() {
        props.setCurrentPage(props.page)
    }

    const info = pageInformation[props.page]

    const resolvePath = useResolvedPath(info.link)
    const currentPage = useMatch({path: resolvePath.pathname, end: true})

    return (
        <Link to={info.link} className={'navbar-item ' + (currentPage ? 'navbar-item-selected' : '')} onClick={handleClick}>
            {info.text}
        </Link>
    );
}

export default NavBarItem;
