import React, {ChangeEvent, FormEvent, useRef, useState} from 'react';
import './Contact.scss';
import {Button, Input, Textarea} from '@nextui-org/react';
import ReCAPTCHA from 'react-google-recaptcha';
import {FormElement} from '@nextui-org/react/types/input/input-props';

interface FormValues {
    name?: string,
    email?: string,
    message?: string,
    captcha?: string | null
}

function Contact() {
    const recaptchaRef = useRef<ReCAPTCHA>(null);


    const [contactFormValues, setContactFormValues] = useState({
        name: '',
        email: '',
        message: '',
        captcha: ''
    } as FormValues)

    function handleFormChange(event: ChangeEvent<FormElement>) {
        setContactFormValues({...contactFormValues, [event.target.name]: event.target.value});
    }

    function handleRecaptchaChange(token: string | null) {
        setContactFormValues({...contactFormValues, 'captcha': token});
    }

    function submitContactForm(event: FormEvent) {
        // PreventDefault to avoid page reloading
        event.preventDefault();

        // if (recaptchaRef.current) {
        //     const recaptchaValue = recaptchaRef.current.getValue();
        //     setContactFormValues({...contactFormValues, 'captcha': recaptchaValue});
        //     console.log('recaptchaValue', recaptchaValue)
        //     console.log('contactFormValues', contactFormValues)
        // }

        const requestOptions = {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
            body: JSON.stringify(contactFormValues)
        };
        fetch('/api/mail.php', requestOptions)
            .then((response) => {
                // Check if response is OK and json or send to catch.
                const contentType = response.headers.get('content-type');
                if (response.ok && contentType && contentType.indexOf('application/json') !== -1) {
                    return response;
                }
                return Promise.reject(response);
            })
            .then(response => {
                // Mail sent
                return response.json().then(data => {
                    console.log('json', data)

                    if (data.sent === true) {
                        toaster('The message has been sent successfully.')

                        // TODO reset mail content
                    }
                });
            })
            .catch(error => {
                // Mail not sent
                // If json it's an expected error
                return (error.json() as Promise<any>).then(data => {
                    console.log('error json', data)

                    toaster('The message was not sent : ' + data.message)
                }).catch(errorParseJson => toaster('An unexpected error occurred.'));
            })
    }

    function toaster(message: string): void {
        console.log('toaster', message);
    }

    return (
        <>
            <form className="contact-form" onSubmit={submitContactForm}>
                <div className="contact-form-top">
                    <Input name="name"
                           labelPlaceholder="Name"
                        // required={true}
                           value={contactFormValues.name}
                           onChange={handleFormChange}
                    />
                    <Input
                        name="email"
                        labelPlaceholder="Email"
                        type="email"
                        // required={true}
                        value={contactFormValues.email}
                        onChange={handleFormChange}
                    />
                </div>
                <Textarea
                    name="message"
                    labelPlaceholder="Message"
                    minRows={5}
                    // required={true}
                    value={contactFormValues.message}
                    onChange={handleFormChange}
                />
                <ReCAPTCHA ref={recaptchaRef} sitekey="6Lc4iKYhAAAAAAi--nXTEAG4gUHkTUaol9RovPeq" onChange={handleRecaptchaChange}/>
                <Button type="submit">Send</Button>
            </form>
        </>
    );
}

export default Contact;
