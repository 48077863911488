import React from 'react';
import './InfoItem.scss';

interface Props {
    infoName: string,
    infoValue: string
}

function InfoItem(props: Props) {

    return (
        <div className="info-line">
            <span className="info-name">{props.infoName}</span>
            <span className="info-value">{props.infoValue}</span>
        </div>
    );
}

export default InfoItem;
