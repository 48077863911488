import React from 'react';
import './NavBar.scss';
import {Page, pageInformation} from '../App';
import NavBarItem from './NavBarItem';
import {Link} from 'react-router-dom';

interface Props {
    currentPage: Page,
    setCurrentPage: (page: Page) => void
}

function NavBar(props: Props) {
    const navBarItemList = Object.values(Page)
        .map(p => (<NavBarItem key={p} page={p} currentPage={props.currentPage} setCurrentPage={props.setCurrentPage}/>))

    return (
        <div className="navbar">
            <Link to={pageInformation[Page.HOME].link}>
                <div className="logo-container">
                    <img className="logo" src="/logo-192x192.png" alt="logo"/>
                    Thibault Rapin
                </div>
            </Link>

            <div className="navbar-item-list">
                {navBarItemList}
            </div>
            <div className="filler">Website under construction</div>
        </div>
    );
}

export default NavBar;
